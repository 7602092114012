import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema, Schema } from 'yup';
import { Stack, Box, SelectChangeEvent, Typography } from '@mui/material';
import { BalanceTypeEnum } from 'enums/payment';

import useToaster from 'hooks/useToaster';
import Modal from 'components/common/Modal';
import TransferIcon from 'components/common/icons/bulk/Transfer';
import Form, { FormSelect, FormInput } from 'components/common/Form';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { createYupSchema, transferPlayerMoneySchema } from 'configs/validationSchemes';
import { translate, TranslationKey } from 'utils/translate';
import { adminWithdrawTypeOptions } from 'configs';
import {
  ActivityCardsValueType,
  ApiErrorType,
  CustomFieldType,
  IAffiliatePaymentWithdrawForm,
  ISettingsPaymentMethod,
} from 'types';
import useScreen from 'hooks/useScreen';
import useMount from 'hooks/useMount';
import { getEnabledSettingsPaymentMethods } from 'api/requests/paymentMethods';
import { getAffiliatesCards } from 'api/requests/affiliate';
import useAuth from 'store/auth';
import usePaymentMethodCustomField from 'store/paymentMethodCustomFields';
import { createWithdraw } from 'api/requests/withdraw';

interface Props {
  onClose: () => void;
}

const WithdrawModal: FC<Props> = ({ onClose }) => {
  const { isMobile } = useScreen();
  const notify = useToaster();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<number | unknown>();
  const [balanceInfo, setBalanceInfo] = useState<ActivityCardsValueType>();
  const [paymentsMethods, setPaymentMethods] = useState<ISettingsPaymentMethod[]>();
  const [paymentTypeOptions, setPaymentTypeOptions] = useState(adminWithdrawTypeOptions);

  const [customFields, { get: getCustomFields }] = usePaymentMethodCustomField();

  const [{ data: user }] = useAuth();

  const validationSchema = useMemo(() => {
    return createYupSchema(
      (customFields.data.data.map((field) => ({ ...field, key: field.name, hint: field.name })) ??
        []) as CustomFieldType[],
      transferPlayerMoneySchema as unknown as ObjectSchema<Schema>,
    );
  }, [customFields.data.data]);

  const resolver = useYupValidationResolver<IAffiliatePaymentWithdrawForm>(
    validationSchema as ObjectSchema<IAffiliatePaymentWithdrawForm>,
  );
  const { control, setValue, handleSubmit, watch } = useForm<IAffiliatePaymentWithdrawForm>({
    resolver,
    defaultValues: {
      type: adminWithdrawTypeOptions?.[0]?.id,
    },
  });

  const selectedType = watch('type');

  useMount(() => {
    getEnabledSettingsPaymentMethods().then((res) => setPaymentMethods(res.data.data));
  });

  useEffect(() => {
    if (selectedMethod) {
      getCustomFields(String(selectedMethod));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMethod]);

  useEffect(() => {
    if (user?.affiliate?.id) {
      (async (): Promise<void> => {
        try {
          const response = await getAffiliatesCards(user.affiliate?.id as number);
          setBalanceInfo(response.data as ActivityCardsValueType);
          const options = adminWithdrawTypeOptions.filter((i) => (response?.data?.[i.key] as number) > 0);
          setPaymentTypeOptions(options ?? []);
          setValue('type', options?.[0]?.id as BalanceTypeEnum);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [user?.affiliate?.id, setValue]);

  const methodOptions = useMemo(
    () => paymentsMethods?.map(({ id, systemMethod }) => ({ id, name: systemMethod.name })),
    [paymentsMethods],
  );

  const handleMethodChange = (e: SelectChangeEvent<unknown>): void => {
    setSelectedMethod(e.target.value);
  };

  const handleCreateSubmit = async (data: IAffiliatePaymentWithdrawForm): Promise<void> => {
    const { type, methodId, amount, ...meta } = data;

    setIsLoading(true);

    try {
      await createWithdraw({ balanceType: type, methodId: Number(methodId), amount, meta });
      setIsLoading(false);
      notify({
        message: 'Success',
        type: 'success',
      });
      onClose();
    } catch (error) {
      setIsLoading(false);
      notify({
        message: ((error as unknown as ApiErrorType)?.text as TranslationKey) || translate('Something went wrong'),
        type: 'error',
      });
    }
  };

  const onSave = async (): Promise<void> => {
    handleSubmit(handleCreateSubmit)();
  };

  return (
    <Modal
      maxWidth={544}
      fullScreen={isMobile}
      saveText="Apply"
      icon={<TransferIcon />}
      title="Withdraw"
      isLoading={isLoading}
      onClose={onClose}
      onSave={handleSubmit(onSave)}
    >
      <Box>
        <Stack spacing={2}>
          <Form onSubmit={handleSubmit(handleCreateSubmit)}>
            <Stack spacing={2}>
              <FormSelect
                required
                control={control}
                disabled={isLoading}
                name="type"
                label="Type"
                options={paymentTypeOptions}
              />
              <FormInput required control={control} name="amount" label="Amount" />
              <FormSelect
                required
                control={control}
                disabled={isLoading}
                name="methodId"
                label="Payment Method"
                options={methodOptions}
                onChange={handleMethodChange}
              />

              {customFields.data.data?.map(({ name, isRequired }) => (
                <FormInput
                  required={isRequired}
                  control={control}
                  name={name}
                  label={name as TranslationKey}
                  key={name}
                />
              ))}
              <Typography variant="body2" fontWeight={500} mb={0.5} color="custom.neutrals.scale.500">
                {balanceInfo &&
                  `${translate('Balance')}: ${selectedType === 'CPA' ? balanceInfo.cpaBalance : balanceInfo.revenueBalance} ${user?.affiliate?.currencyCode}`}
              </Typography>
            </Stack>
          </Form>
        </Stack>
      </Box>
    </Modal>
  );
};

export default WithdrawModal;
